<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入客户编码/客户名称/合同编码" v-model.trim="listQuery.customerName" style="width: 200px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right: 10px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width: 550px" label-width="90px" size="small" inline="true" class="style_form">
            <el-form-item label="开始日期：">
              <el-date-picker style="width: 150px" v-model="listQuery.contractStartTime" type="date" placeholder="起始日期"
                clearable value-format="YYYY-MM-DD" />
              &nbsp;-&nbsp;
              <el-date-picker style="width: 150px" v-model="listQuery.contractEndTime" type="date" placeholder="终止日期" clearable
                value-format="YYYY-MM-DD" />
            </el-form-item>
            <el-form-item label="合同状态：">
              <select-common placeholder="请选择合同状态" v-model:modelValue="listQuery.contractState"
                :options="statusOptions" />
            </el-form-item>
            <!-- <el-form-item label="收款状态：">
              <el-checkbox-group v-model="listQuery.allStatus">
                <el-checkbox v-for="item in chargeOptions" :key="item.value" :label="item.value"></el-checkbox>
              </el-checkbox-group>
            </el-form-item> -->
            <el-form-item label="合同编码：">
              <el-input v-model="listQuery.contractNum" placeholder="请输入合同编码" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="客户：">
              <el-input v-model="listQuery.contactsName" placeholder="请输入编号/名称" clearable></el-input>
            </el-form-item> -->
            <el-form-item label="业务类型：">
              <cascader-common v-model:modelValue="listQuery.businessTypeId" placeholder="请选择业务类型" :options="contractTypeOptions"
                :casProps="casProps" style="width: 147px;" />
            </el-form-item>
            <!-- <el-form-item label="部门：">
              <el-input v-model="listQuery.htbm" placeholder="请输入编号/名称"></el-input>
            </el-form-item> -->
            <el-form-item label="是否签约：">
              <select-common placeholder="请选择是否签约" v-model:modelValue="listQuery.signContract"
                :options="yesOrNo" />
            </el-form-item>
            <el-form-item label="是否续签：">
              <select-common placeholder="请选择是否续签" v-model:modelValue="listQuery.renewalType"
                :options="YES_OR_NO" />
            </el-form-item>
            <el-form-item label="服务方式：">
              <select-common placeholder="请选择服务方式" v-model:modelValue="listQuery.serviceMode"
                :options="serviceOptions" />
            </el-form-item>
            <el-form-item label="审核人：">
              <select-common placeholder="请选择审核人" v-model:modelValue="listQuery.auditName"
                :options="userOptions" />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="primary" icon="Plus" @click="add">新增</el-button>
        <el-button size="small" type="primary" icon="Paperclip" @click="renewal">合同续签</el-button>
        <el-dropdown trigger="click">
          <el-button type="primary" size="small" class="ml_12">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <el-dropdown-item>导入</el-dropdown-item> -->
              <el-dropdown-item @click="exportExcel">导出</el-dropdown-item>
              <!-- <el-dropdown-item>查看附件</el-dropdown-item>
              <el-dropdown-item>上传附件</el-dropdown-item>
              <el-dropdown-item>变更明细</el-dropdown-item>
              <el-dropdown-item>签订日期</el-dropdown-item> -->
              <el-dropdown-item @click="recordInfo">审核历史</el-dropdown-item>
              <el-dropdown-item @click="revokeApprove">撤销提交</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table v-loading="loading" :data="list" :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }" height="auto" style="width: 100%"
        border stripe auto-resize size="mini" @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange" :column-config="{ resizable: true }">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="50">
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="90">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称" minWidth="80"> </vxe-column>
        <vxe-column field="contractNum" title="合同编码" width="80">
          <template #default="scope">
            <span class="clickable_style" @click="contractDetail(scope.row)">{{
              scope.row.contractNum
            }}</span>
          </template>
        </vxe-column>
        <vxe-column field="contractType" title="合同类型" width="90">
          <template #default="scope">
            <span>{{ !scope.row.contractType ? "代账" : "增值" }}</span>
          </template>
        </vxe-column>
        <vxe-column field="businessType" title="业务类型" width="80"> </vxe-column>
        <vxe-column field="contractStartTime" title="开始日期" width="90"> </vxe-column>
        <vxe-column field="contractEndTime" title="结束日期" width="90"> </vxe-column>
        <vxe-column field="contractAmount" title="合同总金额" width="100"> </vxe-column>
        <vxe-column field="receivedAmount" title="已收金额" width="80"> </vxe-column>
        <vxe-column field="paymentCycle" title="收款周期" width="80"> </vxe-column>
        <vxe-column field="signContract" title="是否签订合同" width="100">
          <template #default="scope">
            <span>{{ yesOrNoEnum[scope.row.signContract] || '' }}</span>
          </template>
        </vxe-column>
        <vxe-column field="serviceMode" title="服务方式" width="80"> </vxe-column>
        <vxe-column title="合同状态" width="80">
          <template #default="scope">
            <span>{{ getLabelByValue(scope.row.contractState) }}</span>
          </template>
        </vxe-column>
        <vxe-column field="date" title="结束/终止日期" width="130"> </vxe-column>
        <vxe-column field="auditName" title="审核人" width="80"> </vxe-column>
        <vxe-column field="enterDate" title="录入日期" width="90"> </vxe-column>
        <vxe-column field="enterName" title="录入人" width="80"> </vxe-column>
        <vxe-column title="操作" width="130">
          <template #default="scope">
            <el-button link size="small" @click="del(scope.row)" type="danger"
              :disabled="scope.row.contractState !== 1">删除</el-button>
            <el-button link size="small" @click="handleSubmit(scope.row)"
              :disabled="![1, 6].includes(scope.row.contractState)">提交审核</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList" />
    </div>
  </div>
  <!-- 新增编辑续约合同弹窗 -->
  <contractForm ref="ContractForm" @success="getList" />
  <!-- 提交审核人 -->
  <submitCheck ref="submitCheckRef" @success="getList" />
  <!-- 查看审核记录 -->
  <checkRecord ref="checkRecordRef" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { contractList, delContract, exportContract, revokeContractRecord, contractTypeList, contractApprove } from "@/api/newCrm";
import { findPeople } from "@/api/base";
import {
  CONTRACT_STATUS,
  SERVICE_OPTION,
  YES_OR_NO
} from "@/utils/commonData";
import contractForm from "./components/contractForm.vue";
import submitCheck from "./components/submitCheck.vue";
import checkRecord from "./components/checkRecord.vue";

const { proxy } = getCurrentInstance();
const ContractForm = ref();
const list = ref([]);
const contentStyleObj = ref({});
const loading = ref(false);
const total = ref(0);
const listQuery = ref({
  page: 1,
  limit: 20,
});
const statusOptions = ref([
  ...[{ label: "全部", value: 0 }],
  ...CONTRACT_STATUS,
]);

const serviceOptions = ref([
  ...[{ label: "全部", value: "" }],
  ...SERVICE_OPTION,
]);
const sels = ref([]);
const submitCheckRef = ref();
const checkRecordRef = ref();
const casProps ={
  expandTrigger: "hover",
  label: "name",
  value: "id",
}

const yesOrNo = [
  { label: "是", value: '1' },
  { label: "否", value: '0' },
]
const yesOrNoEnum = {
  1: "是",
  0: "否"
}
const contractTypeOptions = ref([])
const userOptions = ref([])

getBussList()

onMounted(() => {
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list.map(item => {
      return {
        ...item,
        label: item.CnName,
        value: item.id,
      }
    });
  });
  contentStyleObj.value = proxy.$getHeight(210);
  getList();
});

function cancel() {
  listQuery.value = {
    page: 1,
    limit: 20,
  }
  getList()
}
const getList = () => {
  sels.value = []
  loading.value = true;
  contractList({
    ...listQuery.value,
    contractState: listQuery.value.contractState ? listQuery.value.contractState : undefined,
    businessTypeId: listQuery.value.businessTypeId ? listQuery.value.businessTypeId : undefined,
    auditUserId: listQuery.value.auditUserId ? listQuery.value.auditUserId : undefined,
    renewalType: listQuery.value.renewalType ? listQuery.value.renewalType : undefined,
    serviceMode: listQuery.value.serviceMode ? listQuery.value.serviceMode : undefined,
    signContract: listQuery.value.signContract ? listQuery.value.signContract : undefined,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};

function getBussList(){
  contractTypeList({ contractType: '2' }).then((res) => {
    contractTypeOptions.value = res.data.data.list;
  });
}

const handleSelectionChange = (data) => {
  sels.value = data.records;
};
const handleSelectionChangeAll = (data) => {
  sels.value = data.records;
};
const getLabelByValue = (value) => {
  const status = CONTRACT_STATUS.find((item) => item.value === value);
  return status ? status.label : "未知状态";
};
const add = () => {
  ContractForm.value.init();
};
//合同续签
const renewal = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择合同");
    return;
  }
  if (sels.value.length > 1) {
    ElMessage.warning("只能选择一个合同");
    return;
  }
  // let fail = sels.value.find(
  //   (item) => item.contractStatus !== 2 || item.contractStatus !== 3
  // );
  // if (fail) {
  //   ElMessage.warning("只能选择审核通过或结束的合同");
  //   return;
  // }
  ContractForm.value.init(sels.value[0], 'renew');
};
//提交审核
const handleSubmit = (row) => {
  submitCheckRef.value.init(row);
};
//删除合同
const del = (row) => {
  ElMessageBox.confirm("确定要删除此条合同吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    delContract({ ids: [row.id] }).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("删除成功");
        getList();
      }
    });
  });
};
//查看审核历史
const recordInfo = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择合同");
    return;
  }
  if (sels.value.length > 1) {
    ElMessage.warning("只能选择一个合同");
    return;
  }
  checkRecordRef.value.init(sels.value[0]);
};
//合同详情
const contractDetail = (row) => {
  ContractForm.value.init(row);
}

function exportExcel() {
  exportContract({ids: sels.value.map(item => item.id), query: {...listQuery.value}})
    .then(res => {
      if (res.data.msg == "success") {
        window.open(res.data.data.url, '_blank');
      }
    })
}

function revokeApprove() {
  if (sels.value.length == 0) {
    ElMessage.warning('请至少选择一条数据');
    return
  }
  const not5 = sels.value.filter(item => item.contractState !== 5)
  if (not5.length) {
    ElMessage.warning('请选择审核状态为“审核中”的数据')
    return
  }
  ElMessageBox.confirm('确定撤销审核?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    revokeContractRecord({ ids: sels.value.map(item => item.id) }).then(res => {
      if (res.data.msg == 'success') {
        ElMessage.success("撤销成功")
        getList()
      }
    })
  })
}
</script>
<script>
export default {
  name: "contractMine",
};
</script>
<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep(.el-checkbox) {
  margin-right: 15px;
}

.style_form {
  .el-input {
    width: 147px;
  }

  .el-select {
    width: 147px;
    margin: 0;
  }
}

.filter-item {
  margin-right: 15px !important;
}

:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
